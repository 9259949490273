import React, { Component } from "react";


class ProductPage extends Component {
  state = {};
  render() {
    return (
      <div className="bx--grid slideshow">

      </div>
      
      
    );
  }
}

export default ProductPage;
