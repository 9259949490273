import React from 'react';
import BlogPostTemplate from './BlogPostTemplate';
import './BlogPostTemplate.scss'; // Import shared styles
import './BlogPost4.scss'; // Import the new styles
import coverphoto from '../../assets/logos/Flag-European-Union.webp';
import stitchedlogos from '../../assets/logos/stitched_logos.png'; 
import combined_image_larger from '../../assets/logos/combined_image_larger.png';

const BlogPost4 = () => {
  return (
    <div className="blog-post">
      <BlogPostTemplate
        title="Early Mildew Detection in Rose Nurseries: Mythronics Joined EIP Consortium Project"
        date="June 9, 2024"
        author="Mythronics Team"
        image={combined_image_larger}
        metaDescription="Mythronics joins the EIP consortium to revolutionize mildew detection in rose nurseries with AI technology, promoting sustainable agriculture."
      >
        <div className="post-image">
          <img src={stitchedlogos} alt="Logos of Project Partners" />
        </div>
        <div className="post-content">
          <p>
            We at Mythronics are excited to be a part of this advanced EIP project, funded by the European Union, leveraging state-of-the-art AI technology to advance sustainability in rose nurseries. Starting January 2024, this three-year initiative aims to develop a full-scale powdery mildew detection system that automatically scans large fields and sends real-time notifications to farmers through a user-friendly dashboard.
          </p>

          <blockquote>
            "The EIP project aims to develop an autonomous sensor vehicle with integrated, AI-driven image recognition technology for the detection and prevention of mildew in roses, enabled through biostimulant applications to reduce the use of chemical pesticides. The state-of-the-art technology used in developing this autonomous sensor vehicle with AI-driven image recognition will support biostimulant applications, meaning that even a single application reduces a significant amount of chemical pesticide use."
          </blockquote>
          <p>
            Mythronics specializes in image processing techniques for artificial intelligence, mainly designed for application in the agricultural sector. We will develop the AI detection system, which must be integrated into a sensor vehicle to function autonomously. This allows high-resolution cameras, enabled with advanced algorithms, to detect mildew signs early so that interventions can be targeted at the right time and place.
          </p>
          <p>
            We work with:
          </p>
          <ul>
            <li><a href="https://www.compas-agro.nl/" target="_blank" rel="noopener noreferrer"><b>Compas Agro</b></a>: Experts in agriculture research and biostimulants.</li>
            <li><a href="https://npk.nl/" target="_blank" rel="noopener noreferrer"><b>npk design</b></a>: Known for state-of-the-art solutions in industrial design.</li>
            <li><a href="https://www.frankcoenders.nl/" target="_blank" rel="noopener noreferrer"><b>Frank Coenders BV</b></a>: Head Nursery for Roses with the pilot location.</li>
          </ul>

          <p>
            The EIP project provides a significant platform to promote more sustainable agro practices, exciting us at Mythronics about the potential of our AI technology. We help farmers use fewer pesticides by integrating the most advanced AI system for early detection of powdery mildew, promoting the use of biostimulants—natural or biological products that enhance plant growth and resilience.
          </p>
          <p>
            Sustainable agriculture is the future, saving the environment by reducing chemical inputs while maintaining crop health and yield. These biostimulants naturally bolster the ability of plants to resist diseases like powdery mildew.
          </p>
          <p>
            With the support of the European Innovation Network, Compas Agro, npk design, and Frank Coenders BV, we contribute to collective efforts in the agricultural sector, proving that innovation and sustainable solutions can be achieved. We aim to demonstrate that applying the most modern technology and combining it with natural methods can make the future of farming more sustainable and productive.
          </p>
        </div>
        <div className="post-footer">
          <div className="footer-image">
            <img src={coverphoto} alt="European Union Flag" />
          </div>
        </div>
      </BlogPostTemplate>
    </div>
  );
};

export default BlogPost4;
