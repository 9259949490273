import React from "react";
import { Career, CareerCard } from "../../components/Careers";
import { Button, Accordion, AccordionItem, ListItem } from "carbon-components-react";
import Head from "../../components/Header";

const CareerPage = () => {
  return (
    <div className="bx--grid bx--grid--full-width ">
      <Head />
      <div className="bx--row career">
        <div className="bx--col-lg-16 career-page__banner">
          <h3 className="career--title">
            Do you enjoy working hard on making an impact <br></br>in the future of food production? 
          </h3>
          <p className="mini-career-title">
           And experience how it evolves & grows?
          </p>
          <p style={{color:"white"}}>Check our current vacancies below or apply open and propose your impact at <br></br>careers@mythronics.com</p>
        </div>
      </div>
      <div className="bx--grid">
        <div className="bx--row">
          <div className="bx--col-lg-16 carreers--items">
          <h2 className="ttl--about--pg">Openings</h2>
          <Accordion>
    <AccordionItem title="Business developer (horticulture)">
      <section>
        <p>Does Vertical Farming / Indoor farming appeal to you? Do you want to be a forerunner in this branch, and do you want to work for the tech startup in greenhouse horticulture? Then we would like to get in touch with you.</p>
       
       <br></br>
       <h5>We challenge you!</h5>
       <p>
       As a business developer (horticulture) at Mythronics, you will build on the unique position as an independent knowledge provider in Vertical Farming globally. You enter into new relationships and maintain the relationship with the clients and partners. You use your knowledge and the cultivation knowledge developed by Mythronics and innovative management systems to realize crop optimization and yield improvement for clients. You act independently and as a team member, also being the linchpin between clients, fellow researchers, and fellow cultivation specialists. You will work at the intersection of research and application in practice.
       </p>
       <br></br>
       <h5>You are</h5>
       <p>Analytical thinker</p>
       <p>Team player</p>
       <p>Good communicator</p>
       <p>Passionate about A.I. or high tech horticulture</p>
       <br></br>

       <h5>Requirements</h5>
       <p>A motivated, eager to learn, and enterprising.</p>
       <p>Who disposes of good communication and social skills.</p>
       <p>With a 3+ years of experience in sales development.</p>
       <p>knowledge about plant physiology and cultivation processes is a pre!</p>
       <br></br>
       <h5>What do you get in return?</h5>
       <p>impact: A high level of independence and responsibility, but a team of colleagues from whom you can learn a lot.</p>
       <p>Ownership: you will get a lot of responsibility and ownership during your role as a business developer.</p>
       <p> Innovation: There is enough room to bring your ideas to the table; we even appreciate it a lot.</p>
       <p>Environment: you will work in one of the coolest tech incubators YES!Delft!</p>
       <br></br>

       <h5>Interested?</h5>
       <p>Send your motivation along with your resume to <strong>careers@mythronics.com</strong></p> <br></br>
      </section>
    </AccordionItem>
    {/* <AccordionItem title="Research/Internship Robotics control systems (all levels)">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </p>
    </AccordionItem> */}
    <AccordionItem title="ROS developer (Python/C++ )" fill={"black"}>
    <section>
        <p>Does Vertical Farming / Indoor farming appeal to you? Do you want to be a forerunner in this branch, and do you want to work for the knowledge organization in greenhouse horticulture? Then we would like to get in touch with you.</p>
       
       <br></br>
       <h5>We challenge you!</h5>
       <p>
       As a ROS developer, you will contribute to integrating autonomous driving modules within a robotics framework in various hardware platforms. The ideal candidate is a Software Engineer with a proven ability to write production-quality code and who is comfortable collaborating with a team.
       </p>
       <h5>You are</h5>
       <p>Analytical thinker</p>
       <p>Team player</p>
       <p>Good communicator</p>
       <p>Passionate about  A.I.</p>
       <p> Deliver solutions, design, and implement using a robotics</p>
       <br></br>

       <h5>Your role</h5>
       <p>Bug fixing and improving the current framework</p>
       <p> Develop automated work cell, mobile robot navigation, and robotic manipulator control</p>
<p>Design and implement tools to facilitate application development and testing</p>
<p>Development of a robotic agnostic mapping system and application</p>
<p>Work with team members in customer support, field testing, and deployment</p>
<br></br>
       <h5>What do you get in return?</h5>
       <p>Impact: A high level of independence and responsibility, but a team of colleagues from whom you can learn a lot.</p>
       <p>Ownership: you will get a lot of responsibility and ownership during your role as a business developer.</p>
       <p> Innovation: There is enough room to bring your ideas to the table; we even appreciate it a lot.</p>
       <p>Environment: you will work in one of the coolest tech incubators YES!Delft!</p>
       <br></br>

       <h5>Interested?</h5>
       <p>Send your motivation along with your resume to <strong>careers@mythronics.com</strong></p>
      </section>
    </AccordionItem>
    <AccordionItem title="Digital marketing internship" fill={"black"}>
<section>
    <p>Have you ever been passionate about emerging technologies, vertical farming, or indoor farming? Are you proficient at marketing, copywriting, creative content creation, and marketing strategy? Then we would like to get in touch with you.</p>
   
   <br></br>
   <h5>We challenge you!</h5>
   <p>
   We are looking for a Digital Marketing Intern who can manage social media, create content, and communicate the brand's story. Are you up for the challenge?
   </p>
   <br></br>
   <h5>You are</h5>
   <p>
   You are an enthusiastic and creative student of marketing, media, or communication who
   </p>
   <p>motivated by a sense of responsibility and entrepreneurial spirit</p>
   <p>wants to deepen and develop fully in one of the following areas:</p>
   <p>Content creation</p>
   <p>Copywriting (captions, blogs, etc.)</p>
   <p>Online media concepts</p>
   <br></br>

   <h5>Your role</h5>
   <p>You can write in English professionally, and Dutch is a pre!</p>
   <p>You are passionate about outreach, social media, and creative content.</p>
   <p>Thinking up and executing offline marketing actions</p>
   <p>Organizing lifestyle photo shoots</p>
<br></br>
   <h5>What do you get in return?</h5>
   <p>Impact: A high level of independence and responsibility, but a team of colleagues from whom you can learn a lot.</p>
   <p>Ownership: you will get a lot of responsibility and ownership during your role as a business developer.</p>
   <p>Innovation: There is enough room to bring your ideas to the table; we even appreciate it a lot.</p>
   <p>Environment: you will work in one of the coolest tech incubators YES!Delft!</p>
   <br></br>

   <h5>Interested?</h5>
   <p>Send your motivation along with your resume to <strong>careers@mythronics.com</strong></p>
  </section>
</AccordionItem>

  </Accordion>
          </div>
        </div>
      </div>
      {/* <Career className="career__row">
        <CareerCard
          heading="Mechanical Engineer Internship"
          imgsrc={mechanical}
          href="https://www.freshplaza.com/article/9136580/smart-technology-to-optimize-quality-control/"
        >
          <Button disabled>application closed</Button>
        </CareerCard>

        <CareerCard
          heading="Perception/Robotics Internship"
          imgsrc={mechanical}
        >
          {" "}
          <Button 
          href="https://talents.yesdelft.com/careers/robotics-perception-engineer-internship"
>
  Apply now</Button>
        </CareerCard>
        <CareerCard
          heading="Sales & Digital Marketing"
          imgsrc={mechanical}
          href="https://www.freshplaza.com/article/9136580/smart-technology-to-optimize-quality-control/"
        >
          {" "}
          <Button disabled>Opens Soon</Button>
        </CareerCard>
        <CareerCard
          heading="Product Engineer"
          imgsrc={mechanical}
          href="https://www.freshplaza.com/article/9136580/smart-technology-to-optimize-quality-control/"
        >
          {" "}
          <Button disabled>application closed</Button>
        </CareerCard>
        <CareerCard
          heading="Embedded programming Internship"
          imgsrc={mechanical}
          href="https://www.freshplaza.com/article/9136580/smart-technology-to-optimize-quality-control/"
        >
          {" "}
          <Button disabled>Opens Soon</Button>
        </CareerCard>
      </Career> */}
    </div>
  );
};
export default CareerPage;
