import React from "react";
import startlifelogo from "../../assets/imgs/partners/startlife.png";
import ZH from "../../assets/imgs/partners/ZH.png"
import aitlogo from "../../assets/imgs/partners/ait.png";
import robovalleylogo from "../../assets/imgs/partners/ROBO.avif";
import yesdelftlogo from "../../assets/imgs/partners/yes.png";
import tudelftlogo from "../../assets/imgs/partners/TUDelft.png";
import rabobanklogo from "../../assets/imgs/partners/rabobank.png";
import eulogo from "../../assets/imgs/EU_logo.png"
import { PartnerSection, Partners } from "../../components/Partners";
import Head from "../../components/Header";
import MIHALY from "../../assets/team/misi.png"
import SOHEIL from "../../assets/team/sjahanshahi.png"
import PRAKASH from "../../assets/team/prakash.png"
import ARON from "../../assets/team/aron.png"
import usimg from "../../assets/imgs/US.webp"
import { Link as RLink,withRouter  } from "react-router-dom";

// import {motion} from "framer-motion"


const ValueCard = (props) => {
  return (
    <article
      className=" about-card bx--col-md-4 bx--col-lg-4 bx--col-xlg-3 bx--offset-xlg-1"
      style={props.style}
    >
      <h2 className="about-card__heading">
        <strong>{props.heading}</strong>
      </h2>{" "}
    </article>
  );
};
const humanstyle = {
  color: "white",
  backgroundColor: "#019DDA",
  padding: "10px",
};
const ethicstyle = {
  color: "white",
  backgroundColor: "#DCA83A",
  padding: "10px",
};
const innovatestyle = {
  color: "white",
  backgroundColor: "#873976",
  padding: "10px",
};
const donestyle = {
  color: "white",
  backgroundColor: "#3340F9",
  padding: "10px",
};
const AboutUs = () => {
  return (
    <div className="bx--grid bx--grid--full-width about-page">
      <div className="bx--row about-page__banner">

        <div className="bx--col-lg-16 about-page__banner">
          {/* <p className="mini-about-title">
            "We will be 9 billion people by 2050." <br></br>Food and Agriculture Organization
          </p> */}
          <h3 className="aboutt-us--title">
          By 2050, the world's population will reach 9 billion. We're here to help you grow sustainable food locally to feed future generations.
          </h3>
        </div>
      </div>
      <Head />
      <div className="bx--row aboutUs__intro" id="mission">
      <div className="bx--col-lg-16 aboutUs">
          <div className="aboutUs__heading">
            <h2 className="ttl--about--pg">Mission</h2>
            <hr className="dash"></hr>
            <h2 className=" mission">
              Our mission is to build a more <strong>sustainable, inclusive, and healthy food system</strong> through AI and autonomous farming.
            </h2>
          </div>
        </div>
        </div>
        <div className="bx--row">
        <div className="bx--col-lg-8 aboutUs" id="aboutus">
          <div className="aboutUs__heading">
            {" "}
            <h2 className="ttl--about--pg">About us</h2>
            <hr className="dash"></hr>
          </div>
          <p className="problem">
            We are an impact driven startup with a talented team. 
            We have roots in agriculture, and live in the Netherlands. 
            We are proud to deliver our expertise in AI, machine learning and robotics. Together with our team we offer world class services to growers around the world to grow, 
            monitor and optimize crop health and productivity.  <span></span>
            
          </p>
        </div>
        <div className="bx--col-lg-8 aboutUs">
        <img className="about--img" src={usimg}></img>
        </div>

 
        <div className="bx--row aboutUs__cards value--row">
        <div className="bx--col-lg-16 ">

        <h2 className="ttl--about--pg">What we stand for</h2>
            <hr className="dash"></hr>
            </div>
                 
        <ValueCard
          style={humanstyle}
          heading="Value Human Connection"
        ></ValueCard>
        <ValueCard
          style={ethicstyle}
          heading="High Integrity & Ethics"
        ></ValueCard>
        <ValueCard
          style={innovatestyle}
          heading="Always Learn & Innovate"
        ></ValueCard>
        <ValueCard style={donestyle} heading="Getting Things Done"></ValueCard>
      </div>
        <div className="bx--col-lg-16  ourteam--row">
          <div className="">
            <h2 className="ttl--about--pg">Leadership Team</h2>
            <hr className="dash"></hr>
          </div>
        </div>
  {/* <div class="bx--row .about-us-grid "> */}
    <div class="bx--col-lg-8 profile-pix"><img src={SOHEIL}></img><h4 className="names">Soheil Jahanshahi</h4><p className="mini-names">Founder & CEO</p></div>
    {/* <div class="bx--col-lg-8 profile-pix"><img src={MATHIJS}></img><h4 className="names">Mathijs Vermeulen</h4> <p className="mini-names">Co-founder & CTO </p></div> */}
    <div class="bx--col-lg-8 profile-pix"><img src={PRAKASH}></img><h4 className="names">Prakash Radhakrishnan</h4> <p className="mini-names">CTO</p></div>

    {/* <div class="bx--col-lg-8 profile-pix"><img src={ARON}></img><h4 className="names">Aron Ferencz</h4> <p className="mini-names">Data Scientist  </p></div> */}

    {/* <div class="bx--col-lg-8 profile-pix"><img src={MIHALY}></img><h4 className="names">Mihaly Lorinc</h4> <p className="mini-names">Software Developer Intern (robotics)</p></div> */}

    {/* <div class="bx--col-lg-8 profile-pix"><img src={ANNE}></img><h4 className="names">Anne Henssen</h4> <p className="mini-names">Industrial design intern</p></div> */}

{/* </div> */}


      </div>

      <div className="bx--row partners">
        <div className="bx--col-lg-16 aboutUs">
          <div className="aboutUs__heading">
            {/* <hr className="dash"></hr> */}
              <PartnerSection
                className="r4__partners"
                heading={
                  <div>
                    <h2 className="ttl--about--pg">Our network</h2>
                    <hr className="dash"></hr>
                  </div>
                }
              >
                <Partners
                  href={"https://yesdelft.com/"}
                  src={yesdelftlogo}
                ></Partners>

                <Partners
                  src={startlifelogo}
                  href={"https://start-life.nl/"}
                ></Partners>
                <Partners
                  href={"https://robovalley.com/"}
                  src={robovalleylogo}
                ></Partners>
                <Partners
                  href="https://www.zuid-holland.nl/"
                  src={ZH}
                ></Partners>
                <Partners
                  href="https://research-and-innovation.ec.europa.eu/funding/funding-opportunities/funding-programmes-and-open-calls/horizon-2020_en"
                  src={eulogo}
                ></Partners>
                {/* <Partners src={carecenterlogo}></Partners> */}
                {/* <Partners src={tallogo}></Partners> */}
                {/* <Partners
                  href="https://www.startupschool.org/companies/5E5tH7cSNJTx9g"
                  src={YC}
                ></Partners> */}
                                <Partners
                  href="https://www.rabobank.nl/bedrijven/"
                  src={rabobanklogo}
                ></Partners>
                <Partners
                  href="https://www.tudelft.nl/en/"
                  src={tudelftlogo}
                ></Partners>
                                {/* <Partners
                  href="https://www.tudelft.nl/en/"
                  src={RBS}
                ></Partners> */}

                {/* <Partners href="https://www.creagy.ch/" src={creagylogo}></Partners> */}
                {/* <Partners src={GTA}></Partners> */}
              </PartnerSection>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AboutUs;
