// BlogPost1.jsx
import React from 'react';
import BlogPostTemplate from './BlogPostTemplate';
import './BlogPostTemplate.scss'; // Import shared styles
import coverphoto from '../../assets/blogs/news1cover.png';

const BlogPost1 = () => {
  return (
    <BlogPostTemplate
      title="Meet Skout, Mythronics’ Latest Scouting Robot"
      date="January 9, 2023"
      author="Mythronics Team"
      image={coverphoto}
    >
      <p>
        Mythronics continues to lead in AI-driven robotics with the introduction of Skout, their latest scouting robot. Skout is designed to autonomously scan for diseases and assess crop ripeness in greenhouses, creating detailed heat maps at the plant level to identify issues.
      </p>
      <blockquote>
        "What we offer the growers is a way to reduce the manual labor. One robot can cover around 3 hectares, significantly reducing the workload and enhancing early disease detection." - Soheil Jahanshahi, Mythronics
      </blockquote>
      <p>
        Skout's launch is timely, as greenhouses face challenges with disease spread and yield loss. Its advanced technology offers a more reliable and efficient alternative to traditional manual monitoring.
      </p>
      <p>
        The robot's capabilities, including advanced heat mapping and AI, provide growers with precise and actionable insights, enhancing crop management and decision-making.
      </p>
      <p>
        Mythronics remains committed to innovation, with Skout representing a significant step forward in agricultural technology. The company's focus on reducing operational costs and improving efficiency offers promising solutions for the agricultural industry.
      </p>
      <p>
        Discover more about Skout and its role in modern greenhouse operations by reading the full story on <a href="https://www.hortidaily.com/article/9492189/meet-skout-mythronics-latest-scouting-robot/" target="_blank" rel="noopener noreferrer">HortiDaily</a>.
      </p>
    </BlogPostTemplate>
  );
};

export default BlogPost1;
