import React, { useState } from 'react';
import { Tile } from 'carbon-components-react';
import './NewsPage.scss';
import Head from "../../components/Header";
import Header from '../../components/Header';
import { useHistory } from 'react-router-dom';
import imagecover3 from '../../assets/blogs/blog3.webp'
import imagecover4 from '../../assets/logos/combined_image_larger.png'

import imagecover1 from '../../assets/blogs/news1cover.png'
import imagecover2 from '../../assets/blogs/news2AD.png'





// Placeholder data for the blog/news articles
const newsArticles = [
  {
  
    id: 4,
    title: 'Early Mildew Detection in Rose Nurseries: Mythronics Joined EIP Consortium Project',
    image: imagecover4, // Replace with actual image URL if available
    date: "June 9, 2024",
    type: 'Blog'
  },
 {
  
    id: 3,
    title: 'AI-Driven IPM: The Future of Sustainable Agriculture with Skout',
    image: imagecover3, // Replace with actual image URL if available
    date: 'August 8, 2023',
    type: 'Blog'
  },{
    id: 1,
    title: 'Meet Skout, Mythronics’ latest scouting robot',
    summary: 'Mythronics has been active for a while in the field of AI-driven robotics...',
    image: imagecover1,
    date: 'January 9, 2023',
    type: 'News'
  },{
    id: 2,
    title: 'This Robot Doctor Searches for Sick Plants in a Greenhouse',
    image: imagecover2, // Replace with actual image URL if available
    date: 'September 12, 2021',
    type: 'News'
  }
  // ,
  // {
  //   id: 4,
  //   title: 'Automated IPM with Computer Vision',
  //   image: 'https://via.placeholder.com/600x400', // Placeholder image
  //   date: 'November 12, 2023',
  //   type: 'Blog'
  // },
  // {
  //   id: 5,
  //   title: 'AI-Assisted Yield Forecast',
  //   image: 'https://via.placeholder.com/600x400', // Placeholder image
  //   date: 'November 13, 2023',
  //   type: 'Blog'
  // }
  
  // ... add more articles as needed
];

const NewsTile = ({ id, title, image, date,type }) => {
    let history = useHistory();

    const openArticle = (articleId) => {
      // Navigate to the blog post page
      history.push(`/blog/blog-post-${articleId}`);
    };
  
    return (
      <div className="news-tile" onClick={() => openArticle(id)}>
        <div className="news-image-container">
          <img src={image} alt={title} className="news-image" />
        </div>
        <div className="news-info">
        <p className="news-type">{type}</p> 
          <h3 className="news-title">{title}</h3> {/* Adjusted the size here */}
          <p className="news-date">{date}</p>
        </div>
      </div>
    );
  };
  
  const SectionDivider = () => (
    <div className="section-divider">
      <hr />
    </div>
  );
  
  const NewsPage = () => {
    const [filter, setFilter] = useState('All'); // Default to show all
    const FilterButtons = () => (
      <div className="filter-buttons">
        <button onClick={() => setFilter('All')}>All</button>
        <button onClick={() => setFilter('Blog')}>Blog</button>
        <button onClick={() => setFilter('News')}>News</button>
      </div>
    );
    
    return (
        
      <div className="news-page">
        <Header></Header>
        <h1 className="main-title blog-header">Blog and News</h1>
        <FilterButtons />

        <SectionDivider />
        <h2 className="section-title">Latest Updates</h2>
        {/* <SectionDivider /> */}
        <section className="news-section">
          <div className="news-container">
          {newsArticles.filter(article => filter === 'All' || article.type === filter)
      .map((article) => (
        <Tile key={article.id} className="news-tile-container">
          <NewsTile {...article} />
        </Tile>
    ))}
          </div>
        </section>
        {/* <SectionDivider />
        <section className="twitter-feed-section">
          <h2 className="section-title">Twitter Feed</h2> */}
          {/* Placeholder for Twitter feed */}
          {/* <div className="twitter-feed">
            <p>Twitter feed from Mythronics would be displayed here.</p>
          </div>
        </section> */}
      </div>
    );
  };
  
//   const TwitterFeed = () => {
//     return (
//       <div className="twitter-feed">
//         {/* Placeholder for Twitter feed */}
//         <p>Twitter feed from Mythronics would be displayed here.</p>
//         {/* In a real application, you might use something like:
//         <TwitterTimelineEmbed
//           sourceType="profile"
//           screenName="mythronics"
//           options={{ height: 400 }}
//         />
//         */}
//       </div>
//     );
//   };
  

  

export default NewsPage;
