// BlogPost3.jsx
import React from 'react';
import BlogPostTemplate from './BlogPostTemplate';
import './BlogPost3.scss'; // Make sure to create this SCSS file
import imagecover from '../../assets/blogs/blog3.webp'

const BlogPost3 = () => {
  return (
    <BlogPostTemplate
      title='AI-Driven IPM: The Future of Sustainable Agriculture with Skout'
      date="August 8, 2023"
      author="Soheil Jahanshahi"
      image={imagecover} // Replace with actual image URL if available
    >
      <p>
        In the intricate ecosystem of a commercial greenhouse, <strong>scouting for pests</strong> is a critical task 
        that can dictate the success or failure of the entire crop. Traditional methods, while effective, can be 
        time-consuming and labor-intensive. Enter <strong>Skout</strong>, the autonomous robot by Mythronics, 
        designed to revolutionize this process with the power of AI and robotics.
      </p>
      <h2 className="middle-title">Autonomous Precision</h2>
      <p>
        Skout navigates the narrow aisles of greenhouses with ease, its sensors attuned to the slightest signs of 
        pest infestation. Unlike manual scouting, which can be subject to human error, Skout's <strong>computer vision</strong> and <strong>machine learning algorithms</strong> ensure consistent and accurate detection, day or night.
      </p>
      <p>
        The benefits of this technological marvel are manifold. By automating the scouting process, growers can 
        <a href="https://extension.okstate.edu/fact-sheets/ipm-scouting-and-monitoring-for-pests-in-commercial-greenhouses.html" target="_blank" rel="noopener noreferrer"> detect problems early</a>, 
        respond swiftly, and apply treatments in a targeted manner, significantly reducing the need for broad-spectrum pesticides.
      </p>
      <h2 className="middle-title">Sustainable Practices</h2>
      <p>
        The integration of Skout into <strong>Integrated Pest Management (IPM)</strong> strategies aligns with the 
        sustainable practices that are becoming increasingly vital in modern agriculture. Research has shown that 
        <a href="https://www.igi-global.com/chapter/artificial-intelligence-in-integrated-pest-management/268042" target="_blank" rel="noopener noreferrer"> AI-driven IPM</a> can lead to a reduction in pesticide use, promoting a healthier environment and reducing costs.
      </p>
      <p>
        For growers, the implications are clear: adopting AI-powered solutions like Skout is not just about staying 
        ahead of the curve; it's about embracing a future where technology and sustainability go hand in hand to 
        produce healthier crops, protect ecosystems, and ensure food security for generations to come.
      </p>
      {/* Add more content as needed */}
    </BlogPostTemplate>
  );
};

export default BlogPost3;
