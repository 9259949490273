import React from "react";

const PartnerSection = props => (
  <section className={`bx--row ${props.className} partner-section`}>
    <div className="bx--col-lg-16 our--network ">
      <div className="partner-section__heading">{props.heading}</div>
    </div>
    {props.children}
  </section>
);
const Partners = props => {
  return (
    <div className="partner-card bx--col-lg-2 bx--col-max-1">
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {" "}
        <img src={props.src} className="partners__logo" alt="partners"></img>
      </a>
    </div>
  );
};

export { PartnerSection, Partners };
