import React from "react";
import {
  FaLinkedin,
  FaInstagram,
  FaMedium,
  FaTwitterSquare
} from "react-icons/fa";

const Footer = () => (
  <div className="bx--grid bx--grid--full-width landing-page__footer">
    <footer className="footer">
      <div className="bx--row footer__r1">
        <div className="bx--col-lg-16 social__icons">
          <a
            href="https://www.linkedin.com/company/mythronics/"
            target="_blank"
          >
            <FaLinkedin className="icon-social" size={32}></FaLinkedin>
          </a>
          {/* <a href="your link here" target="_blank">
            <FaTwitterSquare
              className="icon-social"
              size={32}
            ></FaTwitterSquare>
          </a> */}
          {/* <a href="your link here" target="_blank">
            {" "}
            <FaMedium className="icon-social" size={32}></FaMedium>
          </a> */}
          <a href="https://www.instagram.com/mythronics/?hl=en" target="_blank">
            {" "}
            <FaInstagram className="icon-social" size={32}></FaInstagram>
          </a>
        </div>
        <div className="bx--col-lg-16 copyright__items">
          <p>© Copyright 2023, Mythronics B.V.<br></br> All right reserved.</p>
        </div>
      </div>
    </footer>
  </div>
)

export default Footer;
