// BlogPost2.jsx
import React from 'react';
import BlogPostTemplate from './BlogPostTemplate';
import './BlogPostTemplate.scss'; // Assuming you have a shared style sheet
import imagecover from '../../assets/blogs/news2AD.png';

const BlogPost2 = () => {
  return (
    <BlogPostTemplate
      title="This Robot Doctor Searches for Sick Plants in a Greenhouse: 'This Way, You Can Save a Large Part of the Harvest in Time'"
      date="November 12, 2023"
      author="Mythronics Team"
      image={imagecover} // Replace with actual image URL
    >
      <p>
        Mythronics, a Delft-based startup, is at the forefront of agricultural innovation with Skout, a robot designed to optimize greenhouse operations. Skout, dubbed the 'robot doctor,' plays a crucial role in combating food waste and enhancing harvest efficiency.
      </p>
      <blockquote className="middle-title">
        "If you can detect a disease early in the greenhouse, you can save a large part of the harvest in time." - Soheil Jahanshahi, Mythronics
      </blockquote>
      <p>
        Leveraging his expertise in self-driving car technology, Soheil Jahanshahi, the founder of Mythronics, is dedicated to assisting farmers, particularly in the Netherlands, a hub for greenhouse farming. The company's robots, powered by AI and smart software, are designed to continuously monitor crops, identify diseases, and determine the optimal time for harvesting.
      </p>
      <p>
        Currently focusing on tomatoes and strawberries, Mythronics is ambitiously working towards developing a robot capable of harvesting, a significant milestone in agricultural robotics. Jahanshahi envisions a future where robots can fully automate the harvesting process, maximizing crop yield and minimizing waste.
      </p>
      <p>
        Operating on a service model, Mythronics offers a cost-effective solution for farmers, aiming to reduce labor costs significantly while enhancing harvest efficiency and quality.
      </p>
      <p>
        Discover more about this innovative approach to modern agriculture by reading the full story on <a href="https://www.ad.nl/tech/deze-robotdokter-zoekt-naar-zieke-planten-in-een-kas-zo-kun-je-groot-deel-van-oogst-op-tijd-redden~abf26fe5/" target="_blank" rel="noopener noreferrer">AD.nl</a>.
      </p>
    </BlogPostTemplate>
  );
};

export default BlogPost2;
