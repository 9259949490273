import React from "react";
import { Link,withRouter  } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';


import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderMenu,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderMenuButton,
  SideNav,
  SideNavItems,
  SideNavMenuItem
} from "carbon-components-react/lib/components/UIShell";
import logo from "../../assets/imgs/logo-white.webp";
// import logo from "../../assets/imgs/myt-log-blg.png";
import SmallForm from "../Forms/SmallForm";
import {Button} from 'carbon-components-react'
let isSideNavExpanded = false;
const onClickSideNavExpand = () => {
  isSideNavExpanded = !isSideNavExpanded;
  console.log("sideNavExpanded?");
  console.log(isSideNavExpanded);
};
const Head = () => {
  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <>
          <Header className="header__main" aria-label="Mythronics">
            {/* <SkipToContent /> */}
            <HeaderMenuButton
              className="hamburger"
              aria-label="Open menu"
              isCollapsible
              onClick={onClickSideNavExpand}
              isActive={isSideNavExpanded}
  
            ></HeaderMenuButton>
            <HeaderName element={Link} to="/" prefix="">
              <span>
                <img src={logo} className="logo--brand" />
              </span>
            </HeaderName>
            <SideNav
              aria-label="Side navigation"
              className="Side_menu"
              expanded={isSideNavExpanded}
              // style={{ backgroundColor: "#fec200" }}
            >
              <SideNavItems>
                <SideNavMenuItem element={Link} to="/" prefix="">
                  HOME
                </SideNavMenuItem>
                <SideNavMenuItem element={Link} to="/about" prefix="">
                  ABOUT US
                </SideNavMenuItem>
                <SideNavMenuItem element={Link} to="/blog" prefix="">
               BLOGS & NEWS 
              </SideNavMenuItem>
                {/* <SideNavMenuItem element={Link} to="/technology" prefix="">
                  TECHNOLOGY
                </SideNavMenuItem> */}
                {/* <SideNavMenuItem className="care" element={Link} to="/consortium">
Care for Elderly                </SideNavMenuItem> */}
                <SideNavMenuItem element={Link} to="/careers" prefix="">
                  CAREERS
                </SideNavMenuItem>

                <SideNavMenuItem element={Link} to="/contact" prefix="">
                  CONTACT
                </SideNavMenuItem>
              </SideNavItems>
            </SideNav>
            <HeaderNavigation aria-label="Home">
            <HeaderMenuItem element={Link} to="/" prefix="">
                HOME
              </HeaderMenuItem>
              {/* <HeaderMenuItem element={Link} to="/technology" prefix="">
                TECHNOLOGY
              </HeaderMenuItem> */}
              
              

              <HeaderMenuItem element={Link} to="/about" prefix="">
               ABOUT US
              </HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/blog" prefix="">
               BLOGS & NEWS 
              </HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/careers">
                CAREERS
              </HeaderMenuItem>

        
              {/* <HeaderMenuItem element={Link} to="/product">
        Product
      </HeaderMenuItem> */}

              

              {/* <HeaderMenuItem className="care" element={Link} to="/consortium">
              Care for Elderly               </HeaderMenuItem> */}
              <HeaderMenuItem element={Link} to="/contact">
                CONTACT
              </HeaderMenuItem>
            </HeaderNavigation>
            <HeaderGlobalBar>
            <HashLink classname="haslink" to="/#demoform" >
<Button className="btn-book" >Book Demo</Button></HashLink>
            {/* <HeaderGlobalAction
              aria-label="Notifications"
              onClick={''}>
              <Login/>
            </HeaderGlobalAction> */}
            </HeaderGlobalBar>
          </Header>
        </>
      )}
    />
  );
};
export default Head;
