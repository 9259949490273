import React from "react";
// Take in a phrase and separate the third word in an array
function createArrayFromPhrase(phrase) {
  const splitPhrase = phrase.split(" ");
  const thirdWord = splitPhrase.pop();
  return [splitPhrase.join(" "), thirdWord];
}
const InfoSection = props => (
  <section className={`bx--row ${props.className} `}>
    {/* <div className="bx--col-lg-16 ">
      <div className="info-section__heading">{props.heading}</div>
      <h4 className="info-section__subheading">{props.subheading}</h4>
    </div> */}
    {props.children}
  </section>
);
const InfoCard = props => {
  const splitHeading = createArrayFromPhrase(props.heading);

  return (
    <>

    <div className="info-card bx--col-sm-16 bx--col-md-8 bx--col-lg-4 bx--col-xlg-4 ">
      <div className="icon__what"> {props.icon}</div>
      <h2 className="info-card__heading">
        {`${splitHeading[0]} `}
        <strong>{splitHeading[1]}</strong>
      </h2>{" "}
      <p className="info-card__body">{props.body}</p>
    </div>
    <div className="bx--col-lg-1"></div>
    </>
  );
};

export { InfoSection, InfoCard };
