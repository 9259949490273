import React, { useRef } from "react";
import {
  Accordion,
  AccordionItem,Link,Button, UnorderedList,ListItem
} from "carbon-components-react";



// import { News, NewsCard } from "../../components/News";

import Head from "../../components/Header";
// import foodlossPic from "../../assets/imgs/handofgod.webp"
import {TwitterTweetEmbed} from "react-twitter-embed"

import SmallForm from "../../components/Forms/SmallForm";
import vpdisease from "../../assets/imgs/vpdisease1.webp"
import vpflower from "../../assets/imgs/flowertomatoes1.webp"
import vprobot from "../../assets/imgs/vprobot1.webp"
import { Link as RLink,withRouter  } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import heroVide2 from "../../assets/videos/website1.mp4"
import { InfoSection, InfoCard } from '../../components/Info';
import Globe32 from '@carbon/icons-react/lib/Globe';
import PersonFavorite32 from '@carbon/icons-react/lib/Pedestrian';
import Checkmark from '@carbon/icons-react/lib/Checkmark';

import Application32 from '@carbon/icons-react/lib/Application';
// import {motion, AnimatePresence} from "framer-motion"
import {Overview,BugVirusMalware,Sustainability,OnPremiseToCloud } from '@carbon/pictograms-react';

import heroVide3 from "../../assets/videos/360Robot.mp4"
import heroVide4 from "../../assets/videos/website1-mobile.mp4"

import labours from "../../assets/imgs/laborshortage.webp"
import drought from "../../assets/imgs/draught1.webp"
import bacteria from "../../assets/imgs/bacteria1.webp"
import margin from "../../assets/imgs/margin.webp"
import greenhouseimg from "../../assets/imgs/TomatoGreenhouse.webp"
import {Quotes} from "@carbon/icons-react"

const props = {
  tabs: {
    selected: 0,
    triggerHref: "#",
    role: "navigation",
  },
  tab: {
    href: "#",
    role: "presentation",
    tabIndex: 0,
  },
};
const LandingPage = () => {
  const ref=useRef(null)
  const handleClick = () => {
    ref.current.scrollIntoView({behavior: 'smooth'});
  };
  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <Head />
   
        <div className="bx--row  overlay">
  <div className="bx--col-lg-16">
          <div className=" video-contanier">
            {/* <SlideShow /> */}

        <video

    className="hero-video"
    autoPlay
    muted
    loop
    
    >
      <source src={heroVide2} type="video/mp4"/>
    </video>
    <video

    className="hero-video2"
    autoPlay
    muted
    loop
    // width={680}
    // heiight={390}
    
    >
      <source src={heroVide4} type="video/mp4"/>
    </video>
          </div>
<div className="bx--col-lg-16 header-content">
  
<h1 className="landing-page__heading">
  {/* Robots for Greenhouse automation  */}
  AI-driven <br></br>farming <br></br>
            
            {/* <p classname="landing-page__sub-heading">Robot service workers for sustainable future and healthy plants. </p> */}
            <HashLink classname="haslink-btn" to="/#challenge" ><Button kind="tertiary" onClick={handleClick} className="learn--btn">Find out more</Button></HashLink>
            {/* <HashLink classname="haslink" to="/technology" ><Button kind="primary" element={Link} to="/technology">See technology</Button></HashLink> */}


          </h1>

          </div>
          


          </div>
        </div>


        <div className="bx--grid prob--sec" id={"challenge"}>
        <div className="bx--row ">
        <div className="bx--col-lg-6 ">
<p className="ttl--problem">Our world's agriculture system is <br></br>broken</p>

        </div>
        <div className="bx--col-lg-10 parag--problem">
          <div className="row">
<p className="parag--txt">The food industry has the biggest impact on our lives and planet earth. <strong>Agriculture production uses about 70% of the earth’s fresh water</strong> and 
we are in a race against time to lower greenhouse gas emissions.<br></br> 
In an era of increasing population and water scarcity, we cannot continue growing food as we did traditionally.
Harvesting of vegetables in <strong>greenhouses is currently the best option for sustainable production methods.</strong><br></br>Currently farmers are trying to adapt to changes but are facing big challenges to produce high quality food, their main challenges are:
</p>
</div>

<div className="bx--grid">
<div className="bx--row">

<div className="bx--col-lg-4 probs">
            <div className="svgs">
             
              <img className="prob--img" width={200} height={200} src={labours}></img>
            </div>
            <h4 className="head-prob">Labor Shortage</h4>{" "}
          </div>
          <div className="bx--col-lg-4 probs">
            <div className="svgs">
            <img className="prob--img" width={200} height={200} src={drought}></img>

            </div>
            <h4 className="head-prob">Resource Scarcity</h4>
          </div>
          <div className="bx--col-lg-4 probs ">
            <div className="svgs">
            <img className="prob--img"  width={200} height={200} src={margin}></img>

            </div>
            <h4 className="head-prob">Small Margins</h4>
          </div>
          <div className="bx--col-lg-4 probs">
          <img className="prob--img" width={200} height={200} src={bacteria}></img>

            <h4 className="head-prob">Disease Outbreak</h4>
          </div>

</div>
</div>
        </div>
          </div></div>


 <div className="bx--grid">

<div className="bx--row " id="valueprop">
 <div className="bx--col-lg-6 sl--header">
        <p className="feature__mini_head solu-mini">
              Cloud monitoring platform to manage your greenhouse
            </p>
          <h2 className="solu-header"> Providing intelligence layer per plant.  </h2>
           
</div>  
<div className="bx--col-lg-10 sl--header">

<p className="parag--solu">

Mythronics autonomous scouting robot is a cloud crop monitoring platform which is enabled by machine learning algorithms that allows yield and IPM forecasting.

          </p> 
          </div> 
</div>
 <InfoSection heading="" className="value--prop sl--header">
  <div className="bx--col-xlg-1 bx--col-lg-1"></div>
        <InfoCard
          
          heading={"Autonomous Scouting"}
          body={   <> <ListItem>
            Daily scouting of greenhouse
          </ListItem>
          <ListItem>
            Identify unproductive plants
          </ListItem>
          <ListItem>
            Locates and reports the issue before it becomes a problem
          </ListItem>
          </> }
          icon={<Overview />}
        />
                <InfoCard
          heading="Plant Optimization"
          body={ 
            <> <ListItem>
            Accurate harvest prediction
          </ListItem>
          <ListItem>
            Track growth rate per plant
          </ListItem>
          <ListItem>
            Improved yield per plant
          </ListItem>
          </>}
          icon={<Sustainability />}
        />
        <InfoCard
          heading="Outbreak Prevention"
          body={<> <ListItem>
            Daily scanning for diseases
          </ListItem>
          <ListItem>
            Identify location of defects 
          </ListItem>
          <ListItem>
            Treat every plant early 
          </ListItem>
          </>}
          icon={<BugVirusMalware />}
        />

      </InfoSection>

</div> 

{/* <div className="bx--grid">

<div className="bx--row">
{/* <div className="bx--col-lg-8"><h1></h1>
<p></p>
</div>
<div className="bx--col-lg-8"><img src={facegrow}></img></div> 
 
 </div></div> */}


      {/* <div className="bx--row start__heading__feature yield">
        <div className="bx--row heading-opening-feature">
          <div className="bx--col-lg-16 title__opening">
            <h1>   Remove uncertainty of your farming operations and gain
              unprecedented information about your crop productivity. Instantly. <br></br><HashLink to="/technology#services">
                <Button className="CTA2" kind="tertiary" >Learn more about our services</Button>
                </HashLink></h1>
             
          </div>
        </div>
      </div>  */}
          <div className="bx--grid">
          <div className="bx--row start__heading__feature yield">
        <div className="bx--row heading-opening-feature">
          <div className="bx--col-lg-16 title__opening">
            <h1>  <Quotes className="normalquote" size={40} /> A healthy food system comes from growers healthy business and his decisions. We believe the next
            breakthrough in agriculture will come from AI and data-centric farming.<Quotes className="upsidedown" size={40}/><br></br>
  <p className="title_qoute">Soheil Jahanshahi — Founder of Mythronics</p> <br></br>
  <HashLink to="/about#mission"><Button className="btn--vision" kind={"tertiary"} >Our mission </Button></HashLink>
              {/* <HashLink to="/technology#services"> */}
                {/* <Button className="CTA2" kind="tertiary" >Learn more about our services</Button> */}
                {/* </HashLink> */}
                </h1>
          </div>
          {/* <div className="bx--col-lg-16 title__opening ">
  "In traditional farming, growers are frequently challenged to make
  critical decisions throughout the season. Our A.I. powered scout robots are here to assist them to grow high quality food without a hassle." <br></br>
  <span className="title_qoute">Soheil Jahanshahi — Founder of Mythronics</span> <br></br>
 <RLink to="/about"> <Button kind="tertiary" classname="btn--vision" style={{margin: "0%"}}>Our vision</Button> </RLink> 
</div> */}
        </div>
      </div>
          </div>

      <div className="bx--grid robo--360-section" id="oursolution">
        <div className="bx--row ">

        <div className="bx--col-lg-8 acess--grid">
        <p className="feature__mini_head solu-mini">
              Complete solution
            </p>
  <h4 className="feat--ttl"> We provide robotic workhorse 
    </h4>
    <p className="feat--parag">
      
      Our first robot worker is named <strong>SKOUT</strong>. <br></br><br></br>SKOUT is reliable and easy to use workhorse which drive autonomously inside your greenhouse while doing an intensive tasks of monitoring and collecting unprecedented parameters per row.
      <br></br> You will receive the following key <strong>benefits</strong>: 
       <br></br>
       <br></br>




<div><Checkmark fill="green" size={20} className="checkmark"></Checkmark>Receive daily report and emergency alerts on your devices</div>

<div><Checkmark fill="green" size={20} className="checkmark"></Checkmark>Reliable forecast of your next harvest</div>

<div><Checkmark fill="green" size={20} className="checkmark"></Checkmark>Stabilize your sales price </div>     
<div><Checkmark fill="green" size={20} className="checkmark"></Checkmark>Save labour by 2 person per 3 hectares</div>     
<div><Checkmark fill="green" size={20} className="checkmark"></Checkmark>Remote control of your greenhouse day and night</div>  
 

   {/* Automated high-quality field data acquisition (boundaries, crop, pest, soil...)
   Fleet of scout robots enables you to have an automated high-quality field data acquisition for crop,pest and diseases. */}

            </p>
            <HashLink to="#demoform"><Button kind="tertiary" className="feat--cta">Request to access Dashboard</Button></HashLink>
            {/* <HashLink to="/technology#yield-section"><Button kind="tertiary" className="">Download Brouchures</Button></HashLink> */}


            
{/* <HashLink to="/technology#yield-section"><Link kind="tertiary" className="feat--cta">Learn more ></Link></HashLink> */}

</div>
<div className="bx--col-lg-8  bleed">
<video

className="hero-video-360robot"
autoPlay
muted
loop
width={"100%"}
height="100%"

>
  <source src={heroVide3} type="video/mp4"/>
</video>
        </div>
        </div>
        </div>


      <div className="bx--grid features--section">
      {/* <div className="bx--row solution--big">

      <h1>Rethink the way you scout</h1>

      </div> */}

        <div className="bx--row ">
        {/* <div className="bx--col-lg-8  sharp-img">
        <p className="feature__mini_head solu-mini">
              Yield forecastin
            </p>
          <h4 className="feat--ttl">The vision driven harvesting  </h4>
          <p className="feat--parag">Our quality detection AI software tool generates </p>
<HashLink to="/technology#yield-section"><Link kind="tertiary" className="feat--cta">Learn more ></Link></HashLink>
  <p>
  </p>        </div> */}
          <div className="bx--col-lg-16  ">
            {/* <h1>features</h1> */}
          </div>

        <div className="bx--col-lg-2  "></div>

        <div className="bx--col-lg-4  "
        >
          <img className="vps" src={vpflower}></img>
          <section className="rect rect1">Track Growth <br></br> <br></br>
          <p classname="sub-paraf">  Monitor weekly if your flowers has successfully set to fruit  </p>

          </section>

        </div>
        <div className="bx--col-lg-4  ">
        <img className="vps" src={vprobot}></img>
        <section className="rect rect2"> Scan Daily <br></br> <br></br>
        <p classname="sub-paraf"> Send SKOUT around greenhouse and register important parameters  </p>
        </section>
        </div>
      
<div className="bx--col-lg-4">
          <img className="vps" src={vpdisease}></img>
          <section className="rect rect1">Monitor Defects <br></br> <br></br>
          <p classname="sub-paraf"> Detects Tomato brown rugose fruit and Pepino Mosaic virus </p>
          {/* <Link className="">request parameters</Link> */}
          </section>
        </div>



          </div>
        </div>
        <div className="bx--grid contact--banner--grow">
        <div className="bx--row bg__small">
        {/* <div className="bx--col-lg-6 ">

        </div> */}
        <div className="bx--col-lg-16 ">
        <h2 className="cta__book">Are you a greenhouse grower, want to use our services?</h2>
        <p className="feat--book">contact us at: growers@mythronics.com <br></br>
        
        <Button className="feat--btn" onClick={handleClick}>Book demo</Button>

</p>



        </div>
        </div>
        </div>
        {/* <div className="bx--grid twitter--feeds">
          <div className="bx--row">
            <div className="bx--col-lg-4">
            <TwitterTweetEmbed
      backgroundColor="#b04949"
      onLoad={function noRefCheck(){}}
      tweetId="1587025438072885250"
    />
            </div>
            <div className="bx--col-lg-4">
            <TwitterTweetEmbed
      backgroundColor="#b04949"
      onLoad={function noRefCheck(){}}
      tweetId="1570346388596133890"
    />
            </div>
            <div className="bx--col-lg-4">
            <TwitterTweetEmbed
      backgroundColor="#b04949"
      onLoad={function noRefCheck(){}}
      tweetId="1565345025004752897"
    />
            </div>
            <div className="bx--col-lg-4">
            <TwitterTweetEmbed
      backgroundColor="#b04949"
      onLoad={function noRefCheck(){}}
      tweetId="1529402918171992064"
    />
            </div>

          </div>
        </div> */}

        <div class  Name="bx--grid last--sec">
 
 <div className="bx--row ">
 <div className="bx--col-lg-8 decision--sec">
 

   <h2 className="titl--desk">Enabling decision support system for your greenhouse through smart farming.  </h2> <br></br>
   {/* <Button style={{color:"white",marginLeft:"20%", borderColor:"white"} } kind="tertiary">Request to access dashboard</Button> */}

</div>
 <div className="bx--col-lg-8  greehousetom">
   {/* <img className="desktop--img" src={imgdesk}></img>  */}
  <img className="desktop--img" src={greenhouseimg}></img> 
 </div>
 </div>

</div>


        <div className="bx--grid formgrid" ref={ref}>
        <div className="bx--row ">
        {/* <div className="bx--col-lg-6 ">

        </div> */}
                  <div className="bx--col-lg-6 bg--team">
                    
          </div>
        <div className="bx--col-lg-10 form " id="demoform">
          <h2 className="feat--ttl--form">Ready to start? <br></br> Schedule a meeting with us</h2>
          <SmallForm></SmallForm>
          </div>

          </div></div>




      </div>
  
  );
};
export default LandingPage;


{/* <div className="bx--col-lg-3 prob-open">
<img src={soheiltomat}/>

</div>
<div className="bx--col-lg-13 prob-open">
  "In traditional farming, growers are frequently challenged to make
  critical decisions throughout the season. Our A.I. powered scout robots are here to assist them to grow high quality food without a hassle." <br></br>
  <span className="title_qoute">Soheil Jahanshahi — Founder of Mythronics</span> <br></br>
 <RLink to="/about"> <Button kind="tertiary" classname="btn--vision" style={{margin: "0%"}}>Our vision</Button> </RLink> 
</div>
*/}