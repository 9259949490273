import React, { useState, useEffect } from "react";
import "./app.scss";
import { Content } from "carbon-components-react/lib/components/UIShell";
import Head from "./components/Header";
import Footer from "./components/Footer";

import { Route, Switch,BrowserRouter } from "react-router-dom";
import LandingPage from "./content/LandingPage";
import ProductPage from "./content/ProductPage";
import ContactPage from "./content/ContactPage";
import AboutUs from "./content/AboutUs";
import CareerPage from "./content/CareerPage";
import NewsPage from "./content/NewsPage";
import BlogPost1 from "./content/NewsPage/BlogPost1";
import BlogPost2 from "./content/NewsPage/BlogPost2";
import BlogPost3 from "./content/NewsPage/BlogPost3";
import BlogPost4 from "./content/NewsPage/BlogPost4";


import { Loading } from "./components/LoadingScreen/Loading";
// import {motion,AnimatePresence} from "framer-motion"

import "@vetixy/circular-std";

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1);
  }, []);

  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  }

  return (
    <>
      {loading === false ? (
        <div className="App">
          {/* <Head /> */}
          <Content>
          <div>
  

<BrowserRouter>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              {/* <Route path="/consortium" component={Tactile} /> */}
              <Route path="/contact" component={ContactPage} />
              <Route path="/about" component={AboutUs} />
              <Route path="/careers" component={CareerPage} />
              <Route path="/technology" component={ProductPage} />
              <Route exact path="/blog/blog-post-1" component={BlogPost1} />
              <Route exact path="/blog/blog-post-2" component={BlogPost2} />
              <Route exact path="/blog/blog-post-3" component={BlogPost3} />
              <Route exact path="/blog/blog-post-4" component={BlogPost4} />

              <Route path="/blog" component={NewsPage} />




            </Switch>
            </BrowserRouter>

            </div>

          </Content>
          <Footer />
        </div>
      ) : (
        <Loading />
      )}
      
    </>
  );
}

export default App;
