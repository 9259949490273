// BlogPostTemplate.jsx
import React from 'react';
import './BlogPostTemplate.scss';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import { useHistory } from 'react-router-dom';


const BlogPostTemplate = ({ title, date, author, image, children }) => {
  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };
  return (
    <article className="blog-post">
      <button onClick={handleBack} className="back-button">Return Back</button>

      <h1 className="blog-post-title">{title}</h1>
      <p className="blog-post-metadata">
        Posted on <time dateTime={date}>{date}</time> by {author}
      </p>
      <figure>
        <img src={image} alt={title} className="blog-post-image" />
      </figure>
      <section className="blog-post-content">
        {children}
      </section>
      <div className="blog-post-share">

        <TwitterShareButton url={shareUrl} title={title}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </article>
  );
};

export default BlogPostTemplate;
