import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { HashRouter as Router } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/compat/app";
import WebFont from "webfontloader";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/compat/analytics";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import CookieConsent, { Cookies } from "react-cookie-consent";
WebFont.load({
  google: {
    families: ["Inter:wght@100;200;300;700;800;900", "sans-serif"],
  },
});
const client = new ApolloClient({
  uri: "https://api.github.com/graphql",
  headers: {
    authorization: `Bearer ${process.env.REACT_APP_GITHUB_PERSONAL_ACCESS_TOKEN}`,
  },
});
// TODO: Replace the following with your app's Firebase project configuration
/**const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};**/

  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseOfficial = {
  apiKey: "AIzaSyCvAtNcmsWIcRkPz1ELC-wKPWMaJgXRttk",
  authDomain: "website-7caf0.firebaseapp.com",
  databaseURL: "https://website-7caf0.firebaseio.com",
  projectId: "website-7caf0",
  storageBucket: "website-7caf0.appspot.com",
  messagingSenderId: "629806619505",
  appId: "1:629806619505:web:13c5277bccde0fbdb1b4d0",
  measurementId: "G-M9YJT8QLN5"
};

const firebaseTestConfig = {
  apiKey: "AIzaSyDt9SR_YL4qkpy8Z8Pdbg2owTBK5D2Uq6s",
  authDomain: "test-website-3f853.firebaseapp.com",
  databaseURL:
    "https://test-website-3f853-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "test-website-3f853",
  storageBucket: "test-website-3f853.appspot.com",
  messagingSenderId: "61708098123",
  appId: "1:61708098123:web:5d8e44ff92812cdb674bce",
};

// Initialize Firebase
firebase.initializeApp(firebaseOfficial);
console.log("firebase initialized");
firebase.analytics();
ReactDOM.render(
  //<ApolloProvider client={client}>
    <Router>
      <App />
    </Router>,
  //</ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
