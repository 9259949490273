import React, {  Component } from "react";
import Form from "carbon-components-react/lib/components/Form";
import FormGroup from "carbon-components-react/lib/components/FormGroup";
import Checkbox from "carbon-components-react/lib/components/Checkbox";

import Button from "carbon-components-react/lib/components/Button";

import TextArea from "carbon-components-react/lib/components/TextArea";
import TextInput from "carbon-components-react/lib/components/TextInput";
import YD from "../../assets/imgs/progressbar.avif";
import firebase from "firebase/compat";
import { FaDrawPolygon } from "react-icons/fa";
import Head from "../../components/Header";
import { v4 as uuidv4 } from 'uuid';



import {
  InlineNotification} from "carbon-components-react/lib/components/Notification";

const CompanyInputProps = {
  className: "Company",
  id: "test2",
  labelText: "Company",
  placeholder: "The name of your company or organization"
};
const NameInputProps = {
  className: "Name",
  labelText: "Name",

  id: "test3",
  placeholder: "What is your full name?"
};
const PhoneInputProps = {
  className: "Phone",
  labelText: "Phone",

  id: "test4",
  placeholder: "Phone number to schedule a call"
};
const EmailInputProps = {
  className: "Email",
  labelText: "Email",

  id: "test5",
  placeholder: "What is your email?"
};
const ExtraInputProps = {
  className: "Anything",
  labelText: "Anything else you want to add?",

  id: "test5",
  placeholder: "Placeholder text"
};
const InvalidPasswordProps = {
  className: "some-class",
  id: "test4",
  labelText: "Password",
  invalid: true,
  invalidText:
    "Your password must be at least 6 characters as well as contain at least one uppercase, one lowercase, and one number."
};
const textareaProps = {
  labelText: "Anything you want to add?",
  className: "some-class",
  placeholder: "Would you like to schedule a call or try our solution? ",
  id: "test5",
  cols: 50,
  rows: 4
};
const checkboxEvents = {
  className: "some-class",
  labelText:
    "General Data Protection Regulation: I understand that my data is stored on this website in order to get contacted."
};

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

      errors: [],
      date: "",
      showPopup: false,
      company: "test",
      submitMessage: "We have received your message, We will contact you soon!",
      errorMessage: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);

    this.formMessage = firebase.database();
  }
  writeUserData = () => {
    this.formMessage
      .ref("/leads/incoming/"+uuidv4() )
      .set(this.state)
      .then(console.log("DATA SAVED"));
    this.setState({ showPopup: true });
  };

  handleSubmit(event) {
    this.writeUserData();

    // if (this.state.company.length < 2) {
    //   this.state.errors.append("A");
    //   console.log(this.state.errors);
    // }
    // console.log("submitted: ", this.state.company);
    // console.log(" submitted: ", this.state.name);
    // console.log(" submitted: ", this.state.email);
    // console.log(" submitted: ", this.state.phone);
    // console.log("submitted: ", this.state.message);
    // console.log("submitted: ", this.state.showPopup);
    // console.log("submitted: ", this.state.checkedGDPR);

    event.preventDefault();
    this.setState({ showPopup: true });
    this.setState({
      company: "",
      name: "",
      email: "",
      phone: "",
      message: "",
      checkedGDPR: false
    });
  }
  handleChangeCompany(event) {
    this.setState({ company: event.target.value });
    this.setState({ date: Date.now() });
  }
  handleChangeName(event) {
    this.setState({ name: event.target.value });
  }
  handleChangePhone(event) {
    this.setState({ phone: event.target.value });
  }
  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleChangeMessage(event) {
    this.setState({ message: event.target.value });
  }
  handleCheckBox(event) {
    console.log(event);
    this.setState({ checkedGDPR: event });
  }
  render() {
    let notify;
    if (this.state.showPopup) {
      notify = (
        <InlineNotification
          kind="success"
          lowContrast
          iconDescription="describes the close button"
          subtitle={<span>{this.submitMessage}</span>}
          title="Sucessfully submitted!"
        />
      );
    } else {
      notify = <div></div>;
    }

    return (
      <div className="bx--grid bx--grid--full-width contact-page">
        <Head/>
        <div className="bx--row contact--banner-row">
          <div className="bx--col-lg-16 contact-page__banner">
            <h1 className="contact-page__heading">Let's work on automation<br></br> together!</h1>
          </div>
        </div>
        <div className="bx--row career">
          <div className="career__heading">
            {" "}
            <h1>
            Can your scouting operations be made any easier?

<br></br>
              {/* <u style={{ color: "#fec200" }}>IN?</u> */}
            </h1>
            <h5 style={{ paddingTop: "20px" }}>
            Contact us to discuss how you can benefit from our services.

{/*             
              <strong style={{ color: "#fec200" }}>
                Xook Sorting System ™
              </strong>{" "} */}
              {/* can help you sort your crops. */}
            </h5>
            <hr className="dash"></hr>
          </div>
        </div>

        <div className={"bx--row  contactForm-section"}>
          <div className="bx--col-lg-8 ">
            <Form className="contact__form" onSubmit={this.handleSubmit}>
              <FormGroup>
                <TextInput
                  {...CompanyInputProps}
                  onChange={this.handleChangeCompany}
                  required={true}
                />
                <TextInput
                  {...NameInputProps}
                  onChange={this.handleChangeName}
                  required={true}
                />
                <TextInput
                  {...PhoneInputProps}
                  onChange={this.handleChangePhone}
                  
                />
                <TextInput
                  {...EmailInputProps}
                  onChange={this.handleChangeEmail}
                  required={true}
                  pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                  
                />
                <TextArea
                  {...textareaProps}
                  onChange={this.handleChangeMessage}
                />
                <Checkbox
                  {...checkboxEvents}
                  id="checkbox-1"
                  onChange={this.handleCheckBox}
                  required
                />
                <Button type="submit" className="submit__btn">
                  Submit
                </Button>
                {notify}

              </FormGroup>
            </Form>
          </div>

          <div className="bx--col-lg-8 contact_details">
            <img className="yd__img" src={YD}></img>
            <div className="contact details">
              <ul className="contact__ul">
                {/* <h4 style={{ paddingTop: "20px" }}>Contact Details</h4> */}

                <li>
                  {" "}
                  <span className="feature__icons">
                    <FaDrawPolygon size={12}></FaDrawPolygon>
                  </span>
                  Address: Bierstraat 123C, 3011XA, Rotterdam
                </li>

                <li>
                  {" "}
                  <span className="feature__icons">
                    <FaDrawPolygon size={12}></FaDrawPolygon>
                  </span>
                  Email: info@mythronics.com
                </li>
                {/* <li>
                  {" "}
                  <span className="feature__icons">
                    <FaDrawPolygon size={12}></FaDrawPolygon>
                  </span>
                  Email Sales: sales@mythronics.com
                </li>
                <li>
                  {" "}
                  <span className="feature__icons">
                    <FaDrawPolygon size={12}></FaDrawPolygon>
                  </span>
                  Email Investment: invest@mythronics.com
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactPage;
