import React, { Component } from 'react';
import {Form,FormGroup,FileUploader,NumberInput,RadioButton,RadioButtonGroup,Button,Select,SelectItem,TextArea,TextInput,Toggle, Checkbox} from "carbon-components-react"
import firebase from "firebase/compat";
import {
    ToastNotification,
    InlineNotification,
    NotificationActionButton
  } from "carbon-components-react/lib/components/Notification";
  import { v4 as uuidv4 } from 'uuid';
const CompanyInputProps = {
    className: "Company",
    id: "test2",
    labelText: "Company*",
    placeholder: "What is the name of your greenhouse/farm?"
  };
  const NameInputProps = {
    className: "Name",
    labelText: "Name*",
  
    id: "test3",
    placeholder: "What is your full name?"
  };
  const PhoneInputProps = {
    className: "Phone",
    labelText: "Phone",
  
    id: "test4",
    placeholder: "phone number to schedule a call"
  };
  const EmailInputProps = {
    className: "Email",
    labelText: "Email*",
  
    id: "test5",
    placeholder: "What is your email?"
  };

  const ExtraInputProps = {
    className: "Anything",
    labelText: "Anything else you want to add?",
  
    id: "test5",
    placeholder: "Placeholder text"
  };
  const InvalidPasswordProps = {
    className: "some-class",
    id: "test4",
    labelText: "Password",
    invalid: true,
    invalidText:
      "Your password must be at least 6 characters as well as contain at least one uppercase, one lowercase, and one number."
  };
  const textareaProps = {
    labelText: "Anything you want to add?",
    className: "some-class",
    placeholder: "Would you like to add extra message ",
    id: "test5",
    cols: 50,
    rows: 4
  };
  const checkboxEvents = {
    className: "some-class",
    labelText:
      "General Data Protection Regulation: I understand that my data is stored on this website in order to get contacted."
  };
  const additionalProps = {
    className: 'some-class',
    onSubmit: (e) => {
      e.preventDefault();
      
    },
  };


  const CropInputProps = {
    className: "Crop",
    labelText: "Type of crop",
  
    id: "test4",
    placeholder: "What type of crop you grow?"
  };
  

  const fieldsetCheckboxProps = () => ({
    className: 'some-class',
    message: 'Show form requirement (message)',
    messageText: 'Form requirement text (messageText)',
    invalid: false
  });

class SmallForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
          errors: [],
          date: "",
          showPopup: false,
          company: "test",
          submitMessage: "We have received your message, We will contact you soon!",
          errorMessage: null
        };
    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeCrop = this.handleChangeCrop.bind(this);

        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);
    
        this.formMessage = firebase.database();
      }
      writeUserData = () => {
        this.formMessage
          .ref("/Plantsense/incoming/"+uuidv4())
          .set(this.state)
          .then(console.log("DATA SAVED"));
        this.setState({ showPopup: true });
      };
    
      handleSubmit(event) {
        this.writeUserData();
    
        // if (this.state.company.length < 2) {
        //   this.state.errors.append("A");
        //   console.log(this.state.errors);
        // }
        // console.log("submitted: ", this.state.company);
        // console.log(" submitted: ", this.state.name);
        // console.log(" submitted: ", this.state.email);
        // console.log(" submitted: ", this.state.phone);
        // console.log("submitted: ", this.state.message);
        // console.log("submitted: ", this.state.showPopup);
        // console.log("submitted: ", this.state.checkedGDPR);
    
        event.preventDefault();
        this.setState({ showPopup: true });
        this.setState({
          company: "",
          name: "",
          email: "",
          phone: "",
          message: "",
          checkedGDPR: false
        });
      }
      handleChangeCompany(event) {
        this.setState({ company: event.target.value });
        this.setState({ date: Date.now() });
      }
      handleChangeName(event) {
        this.setState({ name: event.target.value });
      }
      handleChangePhone(event) {
        this.setState({ phone: event.target.value });
      }
      handleChangeCrop(event) {
        this.setState({ crop: event.target.value });
      }
      handleChangeEmail(event) {
        this.setState({ email: event.target.value });
      }
      handleChangeMessage(event) {
        this.setState({ message: event.target.value });
      }
      handleCheckBox(event) {
        console.log(event);
        this.setState({ checkedGDPR: event });
      }
    render() { 
        let notify;
    if (this.state.showPopup) {
      notify = (
        <InlineNotification
          kind="success"
          lowContrast
          iconDescription="describes the close button"
          subtitle={<span>{this.submitMessage}</span>}
          title="Sucessfully submitted!"
        />
      );
    } else {
      notify = <div></div>;
    }
        return ( 
            <Form className="small-form" onSubmit={this.handleSubmit}>
              <FormGroup>
                <TextInput
                  {...CompanyInputProps}
                  onChange={this.handleChangeCompany}
                  required={true}
                />
                <TextInput
                  {...NameInputProps}
                  onChange={this.handleChangeName}
                  required={true}
                />
                <TextInput
                  {...EmailInputProps}
                  onChange={this.handleChangeEmail}
                  required={true}
                  pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                />
                                <TextInput
                  {...PhoneInputProps}
                  onChange={this.handleChangePhone}
                  required={true}
                  pattern="/^\d{10}$/"
                 
                  
                />

<TextInput
                  {...CropInputProps}
                  onChange={this.handleChangeCrop}
                  pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                />
                {/* <Checkbox
                  {...checkboxEvents}
                  id="checkbox-1"
                  onChange={this.handleCheckBox}
                  required
                /> */}
                                <TextArea
                  {...textareaProps}
                  onChange={this.handleChangeMessage}
                />
                <Button kind="tertiary" type="submit" className="sub-btn">
                  Submit
                </Button>
              </FormGroup>
              {notify}
            </Form>
            


         );
    }
}
 
export default SmallForm;